<route>
{
  "meta": {
    "auth": "sysUserManage"

  }
}
</route>

<template>
  <div class="pageWithTable">
    <bm-cardTabList :list="tabList" current="角色管理"></bm-cardTabList>
    <el-card class="tableContainer">
      <div slot="header" class="clearfix">
        <span>角色列表</span>
        <el-button v-auth="'superAdmin'" @click="handleAdd()" type="success" style="float: right; ">添加角色</el-button>
      </div>

      <el-table :data="list" v-loading="loading" style="width: 100%">
        <el-table-column align="center" prop="id" label="ID"> </el-table-column>
        <el-table-column align="center" prop="roleName" label="角色名称"> </el-table-column>
        <el-table-column align="center" prop="roleDescribe" label="描述"> </el-table-column>
        <el-table-column align="center" prop="peopleNum" label="成员数量"> </el-table-column>
        <el-table-column align="center" label="地区">
          <template slot-scope="scope">
            {{ scope.row.areaIds | formatArea }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="deleteStatus" label="状态">
          <template slot-scope="scope">
            <el-switch
              @change="onStatusChange(scope.row)"
              :value="scope.row.deleteStatus"
              :active-value="0"
              :inactive-value="1"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row)" type="text" size="small">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="footer">
        <el-pagination
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          class="pagination"
        >
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :visible.sync="isShowForm" :close-on-click-modal="false" :title="`${form.id ? '修改' : '添加'}角色`">
      <el-form label-width="100px">
        <el-form-item label="角色名称">
          <el-input v-model.trim="form.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色备注">
          <el-input v-model.trim="form.roleDescribe"></el-input>
        </el-form-item>
        <el-form-item label="选择权限">
          <div class="permissionList">
            <el-tree
              :props="{
                label: 'privilegeName',
                children: 'children'
              }"
              :data="permissionList"
              node-key="id"
              ref="permissionTree"
              show-checkbox
              highlight-current
              v-model="form.permissionList"
            >
            </el-tree>
          </div>
        </el-form-item>

        <el-form-item label="地区权限">
          <!-- @change="handleSelectAll" -->
          <el-select placeholder="请选择" v-model="form.listSysRoleAreaIds" multiple @change="handleSelect">
            <el-option label="全国" :value="-1"></el-option>

            <el-option v-for="item in areaList" :key="item.id" :label="item.areaName" :value="item.id"></el-option>
          </el-select>
          <!-- <el-button @click="handleSelectAll" type="text" style="margin-left:5px">全国</el-button> -->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import tabList from '../tabList'
import { pagination } from '@/mixin/pagination'
import roleSetting from './__com__/roleSetting'
export default {
  mixins: [pagination],
  components: { roleSetting },
  data() {
    return {
      tabList,
      list: [],
      isShowForm: false,
      form: {
        id: '',
        roleName: '',
        roleDescribe: '',
        listSysRoleAreaIds: []
      },
      permissionList: [],
      areaList: []
    }
  },
  computed: {
    ...mapGetters({ allAreaList: 'user/allAreaList' })
  },
  created() {
    this.allAreaList.forEach(item => {
      if (item.disableStatus === false) {
        this.areaList.push(item)
      }
    })
  },

  methods: {
    ...mapActions({ getAreaList: 'user/getAreaList' }),
    handleSelect() {
      if (this.form.listSysRoleAreaIds.includes(-1)) {
        this.form.listSysRoleAreaIds = this.areaList.map(i => i.id).concat(-1)
      }
    },
    handleSelectAll() {
      this.form.listSysRoleAreaIds = this.areaList.map(i => i.id)
    },
    onStatusChange(val) {
      this.$http
        .put('/boom-center-admin-service/sysAdmin/sysRole/updateDeleteStatus', {
          id: val.id,
          deleteStatus: val.deleteStatus ? 0 : 1
        })
        .then(res => {
          this.$message.success('修改状态成功')
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleAdd() {
      this.form = { id: '', roleName: '', roleDescribe: '', listSysRoleAreaIds: [] }
      this.isShowForm = true
    },
    handleEdit(val) {
      this.form.id = val.id
      this.form.roleName = val.roleName
      this.form.roleDescribe = val.roleDescribe
      this.form.listSysRoleAreaIds = val.areaIds.split(',').map(item => {
        return parseInt(item)
      })
      this.isShowForm = true
      this.$http.get(`/boom-center-admin-service/sysAdmin/sysRole/${val.id}`).then(res => {
        this.$refs.permissionTree.setCheckedKeys(res.sysRolePrivilegeVOList.map(i => i.privilegeId))
      })
    },
    handleSubmit() {
      const listSysPrivilegeIds = this.$refs.permissionTree.getCheckedKeys()
      // .concat(this.$refs.permissionTree.getHalfCheckedKeys())
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      Promise.resolve()
        .then(() => {
          if (this.form.id) {
            return this.$http.put(`/boom-center-admin-service/sysAdmin/sysRole/${this.form.id}`, {
              ...this.form,
              listSysPrivilegeIds
            })
          } else {
            return this.$http.post('/boom-center-admin-service/sysAdmin/sysRole', { ...this.form, listSysPrivilegeIds })
          }
        })
        .then(() => {
          this.$message.success('操作成功')
          this.isShowForm = false
          this.getList()
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    getList(val) {
      this.loading = true
      this.currentPage = val === true ? this.currentPage : 1
      this.$http
        .get('/boom-center-admin-service/sysAdmin/sysRole', {
          params: {
            page: this.currentPage,
            size: this.pageSize
          }
        })
        .then(res => {
          this.list = res.list
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.msg || '获取角色列表失败')
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPermissionList() {
      this.$http
        .get('/boom-center-admin-service/sysAdmin/sysPrivilege/selectNodeList')
        .then(res => {
          this.permissionList = res
        })
        .finally(() => {
          this.loading = false
        })
    }
  },

  mounted() {
    this.getAreaList()
    this.getPermissionList()
  }
}
</script>

<style lang="less" scoped>
.permissionList {
  height: 300px;
  overflow-y: auto;
}
</style>
