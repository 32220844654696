export default [
  {
    path: '/setting/accountMange/account',
    name: '账号管理'
  },
  // {
  //   path: '/setting/salesman',
  //   name: '营销人员管理'
  // },
  {
    path: '/setting/accountMange/role',
    name: '角色管理'
  },
  {
    path: '/setting/accountMange/permission',
    name: '权限管理'
  }
]
