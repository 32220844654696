<!--
 * @Author: Link
 * @Date: 2020-11-07 09:09:45
 * @LastEditTime: 2020-11-30 15:04:54
-->
<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogShow"
    :title="`${data.id ? '修改' : '添加'}角色`"
    @close="$emit('close')"
  >
    <el-form label-width="100px">
      <el-form-item label="角色名称">
        <el-input></el-input>
      </el-form-item>
      <el-form-item label="选择权限">
        <div class="permission">
          <el-tree :data="permission" show-checkbox node-key="id"> </el-tree>
        </div>
      </el-form-item>
    </el-form>
    <div class="actions">
      <el-button class="btn outline" @click="$emit('close')">取消</el-button>
      <el-button class="btn">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: Object
  },
  watch: {
    show(v) {
      this.dialogShow = v
    }
  },
  data() {
    return {
      dialogShow: false,
      permission: [
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        },
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        },
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        },
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        },
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        },
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        },
        {
          id: 1,
          label: '商城设置',
          children: [
            {
              id: '1-1',
              label: '查看商城数据'
            },
            {
              id: '1-2',
              label: '商城装修',
              children: [
                {
                  id: '1-2-1',
                  label: '查看'
                },
                {
                  id: '1-2-2',
                  label: '修改'
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.permission {
  max-height: 60vh;
  overflow: auto;
}
.actions {
  text-align: right;
  .btn {
    border-radius: 30px;
    background: #e71414;
    border: 1px solid #e71414;
    &:hover {
      color: #fff;
    }
  }
  .outline {
    background: #fff;
    color: #e71414;
    &:hover {
      color: #e71414;
    }
  }
}
</style>
