let pagination = {
  data: () => ({
    pageSize: 10,
    total: 0,
    currentPage: 1,
    pageSizes: [10, 20, 50, 100],
    loading: false
  }),
  methods: {
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList(true)
    }
  },
  mounted() {
    // this.$log.mixin('table', {
    //   data: 'list page pageSizeList loading',
    //   methods: 'onPageChange onSizeChange initData getList'
    // })
    this.getList()
  }
}
export { pagination }
